import React from 'react';
import PropTypes from 'prop-types';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { hopscotch } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { PreTag, CodeTag } from './CodeBlock.atoms'

const CodeBlock = ({ language, value }) => {
    return (
        <SyntaxHighlighter
            language={language}
            style={hopscotch}
            showLineNumbers={true}
            lineNumberContainerStyle={
                {
                    opacity: 0.5,
                    paddingRight: '20px',
                    lineHeight: 2,
                    float: 'left'
                }
            }
            customStyle={{
                color: 'undefined',
                margin: 'undefined',
                background: 'undefined',
                padding: 'undefined',
                fontSize: 'undefined',
                lineHeight: 'undefined'
            }}
            PreTag={PreTag}
            CodeTag={CodeTag}
            codeTagProps={{style: {} }}
        >
          {value}
        </SyntaxHighlighter>
      );
}

CodeBlock.propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string
};

CodeBlock.defaultProps = {
    language: 'javascript',
    value: 'console.log(\'Hello World\');\nconsole.log(\'Foo\');'
}

export default React.memo(CodeBlock);