import React from 'react';
import Emoji from 'react-emoji-render';
import { navigate } from 'hookrouter';
import { Swing } from 'animate-css-styled-components';

import { Container, Body, Logo, Contact } from './Header.atoms';

const Header = () => {
    return (
        <Container>
            <Body>
                <Logo onClick={() => navigate('/')} />
                <Swing delay='3s'>
                    <Contact>
                        <Emoji text='Hi! 👋' />
                    </Contact>
                </Swing>
            </Body>
        </Container>
    );
}

export default Header;
