import styled from 'styled-components';
import { tint } from 'polished';

export const Container = styled.footer`
  background-color: ${(props) => tint(0.925, props.theme.COLORS.DARK)};
  color: ${(props) => tint(0.2, props.theme.COLORS.DARK)};

  font-size: 0.8rem;

  padding: 40px;
  margin-top: 80px;

  width: inherit;
  flex-grow: 0;
`;

export const Body = styled.div`
  @media (min-width: 670px) {
    width: ${(props) => props.theme.CINCH};
    margin: 0 auto;
  }
`;
