import React from 'react';

import { Container, Hero, HeroBody, Greeting, Iam, Tagline, Portrait } from './Home.atoms';
import ArticleList from './ArticleList/ArticleList';

const Home = () => {
    return (
        <Container>
            <Hero>
                <HeroBody>
                    <Greeting>Hi,</Greeting>
                    <Iam>I'm Vi</Iam>
                    <Tagline>A Front-End architect who writes about things.</Tagline>
                </HeroBody>
                <Portrait src='https://placekitten.com/500/500' />
            </Hero>
            <ArticleList />
        </Container>
    );
};

export default Home;
