import React, { useEffect } from 'react';
import { useRoutes } from 'hookrouter';

import Home from '../../pages/Home/Home';
import Article from '../../pages/Article/Article';

const About = () => <main>About</main>;
const NotFound = () => <main>Not Found</main>;

const routes = {
  '/': () => <Home />,
  '/:articleID': ({ articleID }) => <Article articleID={articleID} />,
  '/about': () => <About />,
};

const Router = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return useRoutes(routes) || <NotFound />;
};

export default Router;
