import React, { useState, useEffect } from "react";

import ArticleCard from "./ArticleCard/ArticleCard";
import { Container, Title } from "./ArticleList.atoms";

import WombatAPI from "../../../utils/Wombat.api";

const ArticleList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    WombatAPI.getArticleList()
      .then(articleList => setArticles(articleList))
      .catch(err => console.error(err))
      .finally(setIsLoading(false));
  }, []);

  return (
    <Container>
      <Title>Articles</Title>

      {isLoading ? (
        <span>Hello I am Loading!</span>
      ) : (
        articles.map(article => {
          return (
            <ArticleCard
              key={article.metadata.id}
              metadata={article.metadata}
            />
          );
        })
      )}
    </Container>
  );
};
export default ArticleList;
