import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';
import ReactHtmlParser from 'react-html-parser';
import WombatAPI from '../../utils/Wombat.api';
import CodeBlock from './renderers/CodeBlock';
import Icon from '../../components/Icon';
import { ReactComponent as arrowAltToLeft } from '../../assets/fontawesome/duotone/arrow-alt-to-left.svg';

import {
  Container,
  Return,
  Header,
  Title,
  Subtitle,
  Date,
  Body,
  Image,
} from './Article.atoms';

const Article = ({ articleID }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [article, setArticle] = useState();

  useEffect(() => {
    WombatAPI.getArticle(articleID)
      .then((articles) => setArticle(articles[0]))
      .finally(setIsLoading(false));
  }, [articleID]);

  const mainTransformer = (node, index) => {
    if (node && node.type === 'tag' && node.name === 'pre') {
      const codeTag = node.children[0];
      let language;
      if (codeTag && codeTag.attribs && codeTag.attribs.class) {
        language = codeTag.attribs.class.split(' ')[0];
      }
      return <CodeBlock key={index} language={language} />;
    }
  };

  return (
    <Container>
      <Return onClick={() => navigate('/')}>
        <Icon svg={arrowAltToLeft} />
        Return
      </Return>

      {isLoading && <span>I am loading...</span>}

      {!isLoading && article && article.metadata && article.content && (
        <React.Fragment>
          <Header>
            <Title>{article.metadata.title}</Title>
            <Subtitle>{article.metadata.subtitle}</Subtitle>
            <Date>{article.metadata.date}</Date>
          </Header>
          <Image src={article.metadata.image} />
          <Body>
            {ReactHtmlParser(article.content, {
              transform: mainTransformer,
            })}
          </Body>
        </React.Fragment>
      )}
    </Container>
  );
};

Article.propTpes = {
  articleID: PropTypes.string.isRequired,
};

export default React.memo(Article);
