import styled, { keyframes } from 'styled-components'
import { tint } from 'polished';

export const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    position: relative;
`;

export const Hero = styled.section`
    color: ${props => props.theme.COLORS.DARK};
    position: relative;
    transition: font-size 0.3s ease;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    margin: 0 40px;

    @media (min-width: 670px) {
        margin: 20px 0;
    }
`;

const PORTRAIT_WIDTH = '100px';
const PORTRAIT_WIDTH_WIDE = '140px';

export const HeroBody = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-right: ${PORTRAIT_WIDTH};
    flex-grow: 1;

    @media (min-width: 400px) {
        margin-right: ${PORTRAIT_WIDTH_WIDE};
    }
`;

const rotateIn = keyframes`
  0% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
`;

export const Portrait = styled.img`
    border-radius: 50%;
    border: 7px solid ${props => props.theme.COLORS.PINK};

    width: ${PORTRAIT_WIDTH};

    position: absolute;
    top: 0;
    right: 0;

    flex-grow: 0;
    object-fit: cover;
    animation: ${rotateIn} 0.6s ease-out both;

    @media (min-width: 400px) {
        width: ${PORTRAIT_WIDTH_WIDE};
    }
`;

export const Greeting = styled.span`
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.2;
`;

export const Iam = styled.span`
    font-family: ${props => props.theme.FONTS.ALTERNATIVE};
    font-weight: 600;
    font-size: 1.6em;
    line-height: 1.6;
    letter-spacing: 2.9px;
`;

export const Tagline = styled.span`
    font-size: 1.4em;
    color: ${props => tint(0.44, props.theme.COLORS.DARK)};
    line-height: 1.2;
`;
