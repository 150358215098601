import React from 'react';

import Router from './Router';
import { Container, Main } from './Layout.atoms';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const Layout = () => {
  return (
    <Container>
      <Header />
      <Main>
        <Router />
      </Main>
      <Footer />
    </Container>
  );
};

export default Layout;
