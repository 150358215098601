const COLORS = {
    BLACK: '#222831',
    DARK: '#393E46',
    WHITE: '#EEEEEE',
    BLUE: '#00ADB5',
    PINK: '#dd6892',
};

const FONTS = {
    BASE: '"Montserrat", sans-serif',
    ALTERNATIVE: '"Montserrat Alternates", sans-serif',
    TITLE: '"Merriweather", serif',
    MONOSPACE: '"Roboto Mono", monospace',
};

export default {
    COLORS,
    FONTS,
    GOLDEN_RATIO: 1.61803398875,
    CINCH: '630px'
}
