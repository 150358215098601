import styled from 'styled-components';
import { shade, tint } from 'polished';

export const Container = styled.div``;

export const Return = styled.a`
  cursor: pointer;

  margin-left: 15px;

  font-family: ${(props) => props.theme.FONTS.ALTERNATIVE};
  color: ${(props) => props.theme.COLORS.BLUE};
  font-size: 0.8rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:hover {
    color: ${(props) => shade(0.2, props.theme.COLORS.BLUE)};
  }

  @media (min-width: 800px) {
    margin-left: -15px;
  }
`;

export const Header = styled.div`
  margin: 20px;

  position: relative;
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.FONTS.TITLE};
  font-weight: 600;
  letter-spacing: 0.1vw;

  color: ${(props) => props.theme.COLORS.DARK};

  margin-bottom: 10px;

  @media (min-width: 800px) {
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.span`
  font-weight: 200;
`;

export const Date = styled.span`
  font-weight: 200;
  color: ${(props) => tint(0.8, props.theme.COLORS.BLACK)};

  position: absolute;
  top: 0;
  right: 0;
`;

export const Body = styled.div`
  margin: 20px;
  line-height: 1.8;
  text-align: justify;

  p {
    margin-top: 20px;
  }

  ul {
    list-style: none;
    margin: 20px 0;
    position: relative;
    li {
      margin-left: 40px;
      &::before {
        content: '●';
        position: absolute;
        left: 0;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin: 20px 0;
    position: relative;
    li {
      counter-increment: item;
      margin-left: 40px;
      &::before {
        content: counter(item) '.';
        position: absolute;
        left: 0;
        font-weight: bold;
      }
    }
  }

  li > p:only-child {
    margin: 0;
  }

  blockquote {
    border-left: 3px solid ${(props) => props.theme.COLORS.BLUE};
    padding: 20px 40px;
    margin: 40px 0;
    color: ${(props) => props.theme.COLORS.DARK};
    font-style: italic;
    & > p:only-child {
      margin: 0;
    }
  }

  hr {
    border-top: 2px dashed ${(props) => props.theme.COLORS.BLUE};
  }

  code {
    background-color: ${(props) => props.theme.COLORS.BLUE};
    color: ${(props) => props.theme.COLORS.WHITE};
    padding: 0 4px;
    border-radius: 7px;
    display: inline-block;
    font-size: 0.8em;
  }

  @media (min-width: 800px) {
    margin: 40px 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 180px;
  margin: 0;

  display: block;

  object-fit: cover;

  @media (min-width: 800px) {
    width: 710px;
    height: 210px;

    margin: 40px -40px;
    border-radius: 24px;
  }
`;
