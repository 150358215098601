import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import Tokens from './theme/Tokens';
import Reset from './theme/Reset';
import Layout from './components/Layout/Layout';

import * as serviceWorker from './serviceWorker';

ReactDOM.render((
    <ThemeProvider theme={Tokens}>
        <React.Fragment>
            <Reset />
            <Layout />
        </React.Fragment>
    </ThemeProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
