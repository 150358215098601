import React from 'react';
import { Twemoji as Emoji } from 'react-emoji-render';

import { Container, Body } from './Footer.atoms';

const Footer = () => {
    return (
        <Container>
            <Body>
                <Emoji text='Made with ❤️ in Stockholm, Sweden.' />
            </Body>
        </Container>
    );
};

export default Footer;
