import React from 'react';
import styled from 'styled-components';

export default ({ svg, width = '10px' }) => {
  const Icon = styled(svg).attrs({
    fill: 'currentColor',
  })`
    width: ${width};
    margin-right: 5px;
  `;
  return <Icon />;
};
