import styled from 'styled-components';
import { tint } from 'polished';
import Oval from './Oval.svg';
import MessageBox from './MessageBox.svg';
import { ReactComponent as LogoSVG } from './Logo.svg';

const scallopHeight = '22.5px';

export const Container = styled.header`
  font-size: 1rem;
  color: ${(props) => props.theme.COLORS.WHITE};

  padding-bottom: ${scallopHeight};
  height: ${80 * 1.618}px;
  flex-grow: 0;

  background-color: ${(props) => props.theme.COLORS.BLUE};
  background-image: url(${Oval});
  background-clip: padding-box;
  background-repeat: repeat-x;
  background-position-y: bottom;
`;

export const Body = styled.div`
  height: inherit;
  margin: 0 auto;
  padding: 0 40px ${scallopHeight} 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 670px) {
    width: ${(props) => props.theme.CINCH};
    padding: 0 0 ${scallopHeight} 0;
  }
`;

export const Logo = styled(LogoSVG)`
  width: 160px;
  margin-left: -35px;
  cursor: pointer;

  @media (min-width: 670px) {
    width: 200px;
  }
`;

export const Contact = styled.button`
  font-size: 0.8em;
  text-align: end;
  line-height: 1.7;

  position: relative;
  width: 80px;
  height: 44px;
  padding: 10px 6px 7px 3px;

  border: none;
  outline: none;
  cursor: pointer;

  background-color: transparent;
  background-image: url(${MessageBox});
  background-repeat: no-repeat;
  background-position: top left;

  color: ${(props) => tint(0.2, props.theme.COLORS.DARK)};

  span {
    font-size: 1.2em;
  }

  @media (min-width: 400px) {
    margin-right: 0;
  }
`;
