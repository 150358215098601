import styled from 'styled-components';
import { tint, shade } from 'polished';

export const Container = styled.article`
    line-height: 1.4;

    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-self: stretch;
    cursor: pointer;


    margin: 0 40px;

    & + & {
        margin-top: 20px;
    }

    @media (min-width: 670px) {
        margin: 0;
    }
`;

export const Body = styled.div``;

export const Date = styled.div`
    font-weight: 200;
    color: ${props => tint(0.8, props.theme.COLORS.BLACK)};
`;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 1.75em;
    color: ${props => props.theme.COLORS.BLACK};
    transition: color 0.15s ease;
    ${Container}:hover & {
        color: ${props => shade(0.2, props.theme.COLORS.BLUE)};
    }
`;

export const Description = styled.p``;
