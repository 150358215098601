import styled from 'styled-components';
import DividerSrc from './divider.svg';

export const Container = styled.section`
    margin-top: 40px;
`;

export const Title = styled.h1`
    height: 26.51px;
    width: 100%;

    margin-bottom: 20px;

    background-image: url(${DividerSrc});
    background-position: center;
    background-repeat: no-repeat;

    text-align: center;
    line-height: 2;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 800;
    font-family: ${props => props.theme.FONTS.ALTERNATIVE};
    color: ${props => props.theme.COLORS.BLUE};
`;
