import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  color: ${(props) => props.theme.COLORS.BLACK};
  font-size: 16px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
`;

export const Main = styled.main`
  margin: 10px auto;
  flex-grow: 1;

  @media (min-width: 670px) {
    width: ${(props) => props.theme.CINCH};
  }
`;
