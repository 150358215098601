import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';

import { Container, Body, Date, Title, Description } from './ArticleCard.atoms';

const ArticleCard = ({ metadata }) => {
    return (
        <Container onClick={() => navigate(`/${metadata.id}`)}>
            <Body>
                <Date>{metadata.date}</Date>
                <Title>{metadata.title}</Title>
                <Description>{metadata.description}</Description>
            </Body>
        </Container>
    );
};

ArticleCard.propTypes = {
    metadata: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    })
};

export default ArticleCard;