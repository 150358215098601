const WOMBAT_API_URL =
  process.env.REACT_APP_WOMBAT_API_URL || "http://localhost:3001";

const getArticleList = async () =>
  fetch(`${WOMBAT_API_URL}/collection?name=blog&props=metadata`).then(res =>
    res.json()
  );

const getArticle = async articleID =>
  fetch(`${WOMBAT_API_URL}/collection?name=blog&items=${articleID}`).then(res =>
    res.json()
  );

export default {
  getArticleList,
  getArticle
};
