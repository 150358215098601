import styled from "styled-components";

export const PreTag = styled.pre`
  background-color: ${props => props.theme.COLORS.BLACK};
  padding: 20px;
  margin: 20px -20px;

  font-family: ${props => props.theme.FONTS.MONOSPACE};
  line-height: 2;
  font-size: 1rem;
  color: ${props => props.theme.COLORS.WHITE};

  @media (min-width: 800px) {
    margin: 40px -40px;
    border-radius: 24px;
    padding: 40px;
  }
`;

export const CodeTag = styled.code``;
